import React, {useContext, useEffect, useState} from 'react';
import {Button, Card, Col, Form, Input, Modal, Popconfirm, Row, Table, Tooltip} from "antd";
import {Divider} from "@material-ui/core";
import {DeleteOutline} from "@material-ui/icons";
import moment from "moment";
import EditIcon from "@material-ui/icons/Edit";
import {ActionsCustomWarehouses, CreateCustomWarehouse} from "../../../api/userBalanceData";
import {useAuth0} from "../../../hooks/react-auth0-spa";
import {useAlert} from "react-alert";
import {DataContext} from "../../../context/dataContext";
import {EyeInvisibleOutlined, EyeOutlined} from "@ant-design/icons";

function CustomStock(props) {

    const [openModal, setOpenModal] = useState({ open: false, type: 'create', data: undefined });
    const [isLoading, setIsLoading] = useState(false);
    const { getTokenSilently, user } = useAuth0();
    const alert = useAlert()
    const [form] = Form.useForm()
    const { userWarehouses, getCustomerWarehouses } = useContext(DataContext);

    const createCustomWarehouse = async(e) => {

        if(openModal.type === 'edit' && openModal.data){
            await editCustomWarehouse(e.title)
            return
        }

        setIsLoading(true)
        const token = await getTokenSilently();
        const request = {
            title: e.title,
            defaultFields: false
        }
        try {
            const response = await CreateCustomWarehouse(token, request)
            // console.log('Response: ', response)
            if(response.status !== 200){
                setIsLoading(false)
                return alert.error('Error al crear tu inventario, intenta de nuevo.')
            }
            setIsLoading(false)
            setOpenModal({ ...openModal, open: false })
            await getCustomerWarehouses()
            form.resetFields()
            return alert.success("Tu bodega ha sido creada correctamente.")
        } catch (error) {
            console.log('Error: ', error)
        }
    }

    const deleteCustomWarehouse = async(inventoryId) => {
        const token = await getTokenSilently();
        setIsLoading(true)
        const request = {
            inventoryId,
            action: 'delete'
        }
        try {
            const response = await ActionsCustomWarehouses(token, request)
            // console.log('Response delete: ', response)
            if(response.status !== 200){
                setIsLoading(false)
                return alert.error('Asegurate de no tener productos en tu inventario al eliminar e intenta de nuevo.')
            }
            await getCustomerWarehouses()
            alert.success('Tu inventario ha sido eliminado correctamente.')
            setIsLoading(false)
        } catch (error) {
            console.log('[ ERROR ] - deleteCustomWarehouse', error)
        }
    }

    const editCustomWarehouse = async(title) => {
        const token = await getTokenSilently();
        setIsLoading(true)
        if(!openModal.data._id){
            return alert.error('Error al editar tu inventario, intenta de nuevo. ( 2 )')
        }
        const request = {
            inventoryId: openModal.data._id,
            action: 'edit',
            title
        }
        try {
            const response = await ActionsCustomWarehouses(token, request)
            // console.log('Response edit: ', response)
            if(response.status !== 200){
                setIsLoading(false)
                setOpenModal({ open: false, type: 'create', data: undefined })
                return alert.error('Error al editar el titulo de tu inventario, intenta de nuevo.')
            }
            await getCustomerWarehouses()
            alert.success('Tu inventario ha sido editado correctamente.')
            setOpenModal({ open: false, type: 'create', data: undefined })
            setIsLoading(false)
        } catch (error) {
            console.log('[ ERROR ] - editCustomWarehouse', error)
        }
    }

    const visibleCustomWarehouse = async(inventoryId) => {
        const token = await getTokenSilently();
        setIsLoading(true)
        const request = {
            inventoryId,
            action: 'visible'
        }
        try {
            const response = await ActionsCustomWarehouses(token, request)
            // console.log('Response visible action: ', response)
            if(response.status !== 200){
                setIsLoading(false)
                return alert.error('Error al mostrar/ocultar tu inventario, intenta de nuevo.')
            }
            await getCustomerWarehouses()
            if(response.data.Visible){
                alert.success('Tu inventario ha sido mostrado correctamente.')
            } else {
                alert.success('Tu inventario ha sido ocultado correctamente.')
            }
            setIsLoading(false)
        } catch (error) {
            console.log('[ ERROR ] - visibleCustomWarehouse', error)
        }
    }

    const columns = [
        {
            title: 'Titulo',
            dataIndex: "Title",
            align: 'center'
        },
        {
            title: "Fecha de creación",
            dataIndex: "",
            align: 'center',
            render: (rowData) => (
                <p>{ moment(rowData.CreatedAt).format('lll') }</p>
            )
        },
        {
            title: "Acciones",
            dataIndex: "",
            align: 'center',
            render: (rowData) => (
                <Row>
                    <Col span={8}>
                        <Tooltip title="Eliminar bodega">
                            <Popconfirm title="¿Estas seguro de eliminar?" description="Esta acción no se podra deshacer" onConfirm={() => deleteCustomWarehouse(rowData._id)}>
                                <Button type="primary" icon={<DeleteOutline />} danger />
                            </Popconfirm>
                        </Tooltip>
                    </Col>
                    <Col span={8}>
                        <Tooltip title="Editar bodega">
                            <Button type="default" icon={<EditIcon />} onClick={() => setOpenModal({ type: 'edit', data: rowData, open: true })} />
                        </Tooltip>
                    </Col>
                    <Col span={8}>
                        <Tooltip title={rowData.Visible ? "Ocultar" : "Mostrar"}>
                            <Button type="primary" icon={rowData.Visible ? <EyeInvisibleOutlined /> : <EyeOutlined />} onClick={() => visibleCustomWarehouse(rowData._id)} />
                        </Tooltip>
                    </Col>
                </Row>
            )
        }
    ]

    useEffect(() => {
        if(openModal.type === 'edit' && openModal.data){
            form.setFieldsValue({
                title: openModal?.data?.Title ?? "",
            })
        }
        if(openModal.type === 'create' && !openModal.data){
            form.resetFields()
        }
    }, [openModal]);

    return (
        <>
            <Card>
                <h3 style={{marginTop: '0'}}>Inventario Personalizado</h3>
                <p>Crea inventarios personalizados para tu hogar o bodega personal y administra al 100% los productos que añadas.</p>
                <Button type="primary" onClick={() => setOpenModal({ data: undefined, open: true, type: 'create' })}>Crear inventario</Button>
                <Divider style={{margin: '20px 0'}}></Divider>
                <Table
                    columns={columns}
                    dataSource={userWarehouses}
                    loading={isLoading}
                />
            </Card>
            <Modal
                open={openModal.open}
                onCancel={() => setOpenModal({data: undefined, open: false})}
                footer={<></>}
            >
                <h3 style={{marginTop: '0'}}>{ openModal.type === 'create' ? 'Crear inventario' : 'Editar título de inventario' }</h3>
                <Form form={form} layout="vertical" onFinish={createCustomWarehouse}>
                    <Row gutter={[10, 10]}>
                        <Col span={24}>
                            <Form.Item
                                label="Título"
                                required
                                name="title"
                                rules={[{required: true, message: 'El nombre es requerido'}]}
                            >
                                <Input placeholder="Ej: Bodega de mi casa"/>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Button loading={isLoading} type="primary" htmlType="submit">{ openModal.type === 'create' ? 'Crear nuevo inventario' : 'Editar título' }</Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    );
}

export default CustomStock;