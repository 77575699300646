import React, { createContext, useState, useEffect, useContext } from "react";
import { Auth0Context } from "./../hooks/react-auth0-spa";

// Api Fetchers
import {FetchUserInfo, GetUserWarehouses} from "./../api/userBalanceData";
import { GetAllShipments } from "./../api/WarehouseShippingAPI";
import { ValidateAuth } from "../api/userBalanceData";

export const DataContext = createContext();

export const DataContextProvider = (props) => {

  const [isLoading, setIsLoading] = useState(false)
  const [userWarehouses, setUserWarehouses] = useState([]);
  const [productType, setProductType] = useState('default');
  const [inventoryId, setInventoryId] = useState("default")
  const [data, setData] = useState({
    userBalanceData: 0,
    wareHouseShipmentData: [],
    outboundShipmentDetail: [],
    updateData: true,
  });

  const { user, getTokenSilently, isAuthenticated } = useContext(Auth0Context);

  const getUserData = async() => {
    setIsLoading(true)
    const token = await getTokenSilently();
    await ValidateAuth(token, user.sub)
    const response = await FetchUserInfo(token, user.sub)
    if(response.success){
      setData({
        ...data,
        userBalanceData: response.res.balance,
        userInfo: response.res 
      })
    }
    setIsLoading(false)
  }

  useEffect(() => {
    if(isAuthenticated && user){
      getUserData()
    }
  }, [getTokenSilently, isAuthenticated, user])

  useEffect(() => {
    const dataFetcher = async () => {
      setIsLoading(true)
      const token = await getTokenSilently();

      // Fetch All User Data
      const wareHouseShipmentData = await GetAllShipments(token);

      setData({
          ...data,
          wareHouseShipmentData: wareHouseShipmentData,
          updateData: false,
      });

      setIsLoading(false)
    };

    if (user && isAuthenticated && data.updateData) {
        dataFetcher();
        getCustomerWarehouses();
    }
  }, [user, data, getTokenSilently, isAuthenticated]);

  // Context data for customFields and customWarehouses

  const getCustomerWarehouses = async() => {
      setIsLoading(true)
      const token = await getTokenSilently();
      try {
          const response = await GetUserWarehouses(token)
          setUserWarehouses(response.data)
          setIsLoading(false)
      } catch (error) {
          // console.log('[ ERROR - CONTEXT ] Get Customer Warehouses: ', error);
          setIsLoading(false)
          return alert.error('Error al obtener inventarios, recarga la pagina.')
      }
  }

  return (
    <DataContext.Provider value={{
        data,
        setData,
        isLoading,
        userWarehouses,
        getCustomerWarehouses,
        setProductType,
        productType,
        inventoryId,
        setInventoryId
    }}>
      {props.children}
    </DataContext.Provider>
  );
};
