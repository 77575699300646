import { CheckOutlined, ProfileOutlined, UploadOutlined } from '@ant-design/icons'
import { Button, Card, Col, Popconfirm, Row, Table, Upload } from 'antd'
import Title from 'antd/lib/typography/Title'
import React, {useContext, useState} from 'react'
import * as XLSX from 'xlsx'
import { useAuth0 } from '../../hooks/react-auth0-spa'
import { CreateMassiveProducts } from '../../api/ProductAPI'
import { useAlert } from 'react-alert'
import { useHistory } from 'react-router-dom'
import {DataContext} from "../../context/dataContext";

const InitialLoadingState = { buttonDownload: false, table: false, buttonSubmit: false, buttonSave: false }

const CreateWithXLSX = () => {

    const [ isLoading, setIsLoading ] = useState(InitialLoadingState)
    const [ uploadData, setUploadData ] = useState([])
    const { inventoryId } = useContext(DataContext)
    const alert = useAlert()

    const { user, getTokenSilently } = useAuth0()
    const History = useHistory();

    const renameFields = (field) => {
        switch (field) {
            case 'URL Foto Producto':
                return 'Fotografia'
            case 'SKU':
                return 'SKUCliente'
            case 'Descripcion corta':
                return 'Descripcion'
            case 'Costo':
                return 'ValorMercancia'
            case 'Venta Amazon':
                return 'VentaAmazon'
            case 'Venta MercadoLibre':
                return 'VentaMeli'
            case 'Venta Ecommerce':
                return 'VentaEcommerce'
            case 'Largo':
            case 'Ancho':
            case 'Altura':
            case 'PesoKG':
            case 'Nombre':
            default:
                return field;
        }
    }

    const columns = [
        {
            title: 'Fotografia',
            dataIndex: '',
            align: 'center',
            render: (product)=> (
                <img
                    src={product.Fotografia}
                    alt={product.Nombre}
                    width="80"
                    height="80"
                />
            )
        },
        {
            title: "SKU",
            dataIndex: 'SKUCliente',
            align: 'center',
        },
        {
            title: "Nombre",
            dataIndex: "Nombre",
            align: 'center',
        },
        {
            title: "Descripcion",
            dataIndex: "Descripcion",
            align: 'center',
        },
        {
            title: "Peso ",
            dataIndex: "PesoKG",
            align: 'center',
            showSorterTooltip: false,
            sorter: (a, b) => a.PesoKG - b.PesoKG,
            render: (PesoKG) => `${PesoKG} Kg.`
        },
        {
            title: "Costo",
            dataIndex: "",
            align: "center",
            render: (obj) => `${obj?.ValorMercancia ? `$${obj.ValorMercancia}` : '$0'}`
        },
        {
            title: "Venta Amazon",
            dataIndex: "",
            align: "center",
            render: (obj) => `${obj.VentaAmazon ? `$${obj.VentaAmazon}` : '$0'}`
        },
        {
            title: "Venta MercadoLibre",
            dataIndex: "",
            align: "center",
            render: (obj) => `${obj.VentaMeli ? `$${obj.VentaMeli}` : '$0'}`
        },
        {
            title: "Venta Ecommerce",
            dataIndex: "",
            align: "center",
            render: (obj) => `${obj.VentaEcommerce ? `$${obj.VentaEcommerce}` : '$0'}`
        },
        {
            title: "Dimensiones ",
            dataIndex: "",
            align: 'center',
            render: (obj) => `${obj.Largo} x ${obj.Ancho} x ${obj.Altura}`
        },
    ]

    const formatData = (data) => {
        let newArray = []
        data.forEach((item, index) => {
            let newData = {}
            let counter = 0
            if(!user.sub){
                return alert.error("Error, recarga e intentalo de nuevo.")
            }
            if(inventoryId !== 'default'){
                newData.InventoryId = inventoryId
            }
            if(index > 0){
                while(counter <= 11){
                    newData.UsuarioId = user.sub
                    newData[renameFields(data[0][counter])] = item[counter]
                    if(!item[counter]){
                        newData[renameFields(data[0][counter])] = 0
                    }
                    counter++
                }
                newArray.push(newData)
            }
        })
        return newArray
    }

    const downloadTemplate = () => {
        setIsLoading({ ...isLoading, buttonDownload: true })
        const wb = XLSX.utils.book_new()
        let ws_data;
        if(inventoryId === 'default'){
            ws_data = [
                ["URL Foto Producto","SKU", "Nombre", "Descripcion corta", "Largo", "Ancho", "Altura", "PesoKG", "Costo", "Venta Amazon", "Venta MercadoLibre", "Venta Ecommerce"],
                ["https://123cuidatuhogar.com/wp-content/uploads/2021/11/telefono.png","123SKUTest", "Producto Prueba", "De color rojo y agradable para todos", 20, 10, 10, 1,140,400,390,300]
            ]
        }
        if(inventoryId !== 'default'){
            ws_data = [
                ["URL Foto Producto","SKU", "Nombre", "Descripcion corta", "Largo", "Ancho", "Altura", "PesoKG", "Costo"],
                ["https://123cuidatuhogar.com/wp-content/uploads/2021/11/telefono.png","123SKUTest", "Producto Prueba", "De color rojo y agradable para todos", 20, 10, 10, 1,140]
            ]
        }

        const worksheet = XLSX.utils.aoa_to_sheet(ws_data)

        XLSX.utils.book_append_sheet(wb, worksheet, "Plantilla")
        XLSX.writeFile(wb, "Plantilla.xlsx")
        setIsLoading({ ...isLoading, buttonDownload: false })
    }

    const handleUpload = (info) => {
        setIsLoading({ ...isLoading, buttonSubmit: true, table: true })
        if(info.file.status === 'removed'){
            setUploadData([])
            setIsLoading(InitialLoadingState)
            return
        }
        const file = info?.fileList[0]?.originFileObj;
        const reader = new FileReader();
    
        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: "array" });
            const firstSheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[firstSheetName];
            const json = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
            const response = formatData(json)
            // console.log(response)
            setUploadData(response)
        };

        reader.readAsArrayBuffer(file);
        setIsLoading(InitialLoadingState)
    };

    const addProducts = async() => {
        setIsLoading({ ...isLoading, table: true, buttonSave: true })
        const userToken = await getTokenSilently()
        const type = inventoryId !== 'default' ? 'customWarehouse' : 'default'
        try {
            if(uploadData.length > 0){
                const response = await CreateMassiveProducts(userToken, uploadData, type);
                // console.log(response)
                if(response.status == 200){
                    setIsLoading(InitialLoadingState)
                    setUploadData([])
                    History.push('/products')
                    return alert.success("Productos añadidos correctamente!")
                }
            }
            setIsLoading(InitialLoadingState)
        } catch (error) {
            setIsLoading(InitialLoadingState)
            return alert.error('Error al agregar productos, verifica el excel e intenta nuevamente.')
        }
    }

  return (
    <>

        <Row gutter={[20]} style={{ marginBottom:'20px' }}>
            <Col span={12} style={{ height:'100%' }}>
                <Card style={{ height:'100%' }}>
                    <h3 style={{ marginTop:'0' }}>INSTRUCCIONES</h3>
                    <p>1. Descarga la plantilla de Excel (XLSX) en la cual ingresarás la información de tus productos.</p>
                    <p>2. Una vez completada la información, sube el archivo y verifica que los productos estén correctos.</p>
                    <p>3. Tras revisar la información, confirma para agregar los productos de manera masiva.</p>
                </Card>
            </Col>
            <Col span={12} style={{ height:'100%' }}>
                <Card style={{ height:'100%' }}>
                    <h3 style={{ color:'red', marginTop:'0' }}>PRECAUCIÓN</h3>
                    <p>1. Las primeras 9 columnas son obligatorias. Las columnas de Venta en Amazon, Ecommerce o MercadoLibre pueden dejarse vacías o, preferiblemente, ingresar un cero.</p>
                    <p>2. No incluyas signos ni caracteres extraños. Solo se permite ingresar NÚMEROS y TEXTO. Puedes usar el producto de ejemplo que viene en la plantilla como referencia.</p>
                    <p>3. No modifiques el nombre de las columnas ni cambies su posición.</p>
                </Card>
            </Col>
        </Row>
        <Card>
            <div style={{ display:'flex', justifyContent:'flex-start', gap:'20px' }}>
                <Button loading={isLoading.buttonDownload} onClick={downloadTemplate} icon={<ProfileOutlined />} type='primary'>1) Descargar Plantilla XLSX</Button>
                <Upload
                    beforeUpload={() => false}
                    onChange={handleUpload}
                    listType='text'
                >
                    <Button loading={isLoading.buttonSubmit} disabled={uploadData.length > 0} type='primary' ghost icon={<UploadOutlined />}>2) Subir y Convertir Excel</Button>
                </Upload>
                <Popconfirm okText="Confirmar y agregar" cancelText="Cancelar" onConfirm={addProducts} title="¿Estas seguro de agregar los productos?" description="Revisa bien la tabla de productos para verificar que todo este bien.">
                    <Button loading={isLoading.buttonDownload} icon={<CheckOutlined />} type='primary' disabled={uploadData.length === 0} ghost>3) Confirmar y Agregar Productos</Button>
                </Popconfirm>
            </div>
            <Table
                pagination={{ pageSize: 5 }}
                size='small'
                dataSource={uploadData}
                loading={isLoading.table}
                columns={columns}
                title={() => ( <Title level={4}>Visualización de productos a cargar</Title> )}
            />
        </Card>
    </>
  )
}

export default CreateWithXLSX