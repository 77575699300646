import React, {useContext, useEffect, useState} from 'react';
import CustonTabs from '../../components/tables/CustonTabs';
import ActiveProducts from './ActiveProducts';
import InactiveProducts from './InactiveProducts';
import { UserIntegrationCheck} from '../../api/userBalanceData';
import { useAuth0 } from "../../hooks/react-auth0-spa"
import {integrations} from "../integrationsDispatch/integrations-array";
import SelectIntegration from "./IntegrationStock/SelectIntegration";
import {DataContext} from "../../context/dataContext";

const Products = () => {

  const { getTokenSilently } = useAuth0()
  const { userWarehouses } = useContext(DataContext);
  const [tabs, setTabs] = useState([
    {
      title: 'Drevfill',
      component: <ActiveProducts />
    },
    {
      title: 'Inactivos',
      component: <InactiveProducts />,
    }
  ])

  const switchTitle = (type) => {
    switch (type) {
      case 'MERCADOLIBRE':
        return "MercadoLibre"
      case "AMAZON":
        return "Amazon"
      default:
        return type
    }
  }

  const getCustomerWarehouses = async() => {
    try {
      userWarehouses.forEach(item => {
        item.Visible && setTabs(prevState => [...prevState, { title: item.Title, component: <SelectIntegration type="CUSTOM_WAREHOUSE" inventoryId={item?._id} /> }])
      })
    } catch (error) {
      return alert.error('Error al obtener inventarios, recarga la pagina.')
    }
  }

  const userIntegrationCheck = async() => {
    const token = await getTokenSilently()

    const response = await UserIntegrationCheck(token)
    if(!response?.res?.hasIntegration || response?.res?.integrationData.length === 0){
      console.log('Usuario sin integracion')
      return
    }

    // Validamos que el usuario tenga una integración de las que tenemos disponibles
    const activeIntegrations = []
    for (const integration of integrations) {
      if(integration.status){
        const _int = response.res.integrationData.filter(item => item.type === integration.name)
        activeIntegrations.push(_int[0])
      }
    }
    if(activeIntegrations.length > 0){
      activeIntegrations.forEach(item => {
        setTabs((prevState) => [...prevState, { title: switchTitle(item.type), component: <SelectIntegration type={item.type} /> } ])
      })
    }
  }

  useEffect(() => {
    userIntegrationCheck()
    getCustomerWarehouses()
  }, [])//eslint-disable-line

  return (
    <CustonTabs tabs={tabs} />
  )
}

export default Products