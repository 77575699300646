import React from 'react';
import {Col, Row} from 'antd';
import CustomFields from "./CustomFields";
import CustomStock from "./CustomStock";

function ConfigProducts() {

    return (
        <Row gutter={[10,10]}>
            <Col xs={24} sm={24} md={12} lg={12}>
                <CustomStock />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
                <CustomFields />
            </Col>
        </Row>
    );
}

export default ConfigProducts;
