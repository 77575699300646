import React, { useContext } from "react";
import {
  CircularProgress,
  Tooltip,
} from "@material-ui/core";
// import LogoDrevFill from "../../../public/Logo-2.png";
import { useAuth0 } from "../../hooks/react-auth0-spa";
import { DataContext } from "./../../context/dataContext";
import { Button, Layout, Menu } from "antd";
import { HomeOutlined, LoadingOutlined, LogoutOutlined } from '@ant-design/icons';
import { NavbarRoutes } from "./NavbarRoutes";
import { Link } from "react-router-dom";
import HeaderRoutes from './Header'
import formatearPrecio from "../../utils/PirceFormatter";


const CustomAppBar = (props) => {
  const { user, logout } = useAuth0();
  const { data } = useContext(DataContext);

  const { Header, Content, Footer, Sider } = Layout;

  return (
    <>
      <Layout style={{ width: '100vw', height:'100vh' }}>
        <Sider
          breakpoint="lg"
          collapsedWidth="0"
          onBreakpoint={(broken) => {
            console.log(broken);
          }}
          onCollapse={(collapsed, type) => {
            console.log(collapsed, type);
          }}
        >
          <Menu
                mode="inline"
                style={{ height: "100%", padding: "20px 0", zIndex: "999" }}
            >
                <div style={{ width: '100%', height:'4rem', display:'grid', placeItems:'center' }}>
                  <img alt="DrevFill-Logo" src="Logo-2.png" style={{ width:'10em' }} />
                </div>
                <Menu.Item
                  key="/"
                  icon={<HomeOutlined />}
                >
                  <Link to="/">
                    Inicio
                  </Link>
                </Menu.Item>
                {Object.values(NavbarRoutes).map((section) => {
                    return (
                        <>
                            {section.type === "item" && data?.userInfo?.roles.includes(section.role) ? (
                                <Menu.Item
                                    key={section.route}
                                    icon={section.icon}
                                >
                                  <Link to={section.route}>
                                    {section.title}
                                  </Link>
                                </Menu.Item>
                            ) : (
                                    data?.userInfo?.roles.includes(section.role) && section.type === "subitem" &&
                                    section.children && (
                                        <Menu.SubMenu title={section.title} icon={section.icon} key={section.route}>
                                            {section.children.map((item) => {
                                                return (
                                                    <Menu.Item
                                                        key={item.route}
                                                    >
                                                      <Link to={item.route}>
                                                        {item.title}
                                                      </Link>
                                                    </Menu.Item>
                                                )
                                            })}
                                        </Menu.SubMenu>
                                    )
                                )
                            }
                        </>
                    )
                })}
            </Menu>
        </Sider>
        <Layout>
          <Header
              className="background"
              style={{
                  color: '#FFF',
                  height: "20em",
                  boxShadow: "0px 5px 10px 1px #00000004",
              }}
          >
              <div style={{ display:'flex', alignItems:'center', justifyContent:'space-between', height:'100%' }}>
                <p>
                  {!user 
                    ? (
                      <LoadingOutlined />
                    )
                    : (
                      <p style={{ fontSize:'18px', userSelect:'none' }}>
                          <HeaderRoutes />
                      </p>
                    )
                  }
                </p>
                  <div style={{ display:'flex', alignItems:'center', margin:'-120px 0 0 0' }}>
                      <Tooltip title="Recarga saldo en drenvio.com">
                          <Button type="primary" style={{ fontSize:'15px', cursor: 'pointer', userSelect:'none', padding:'20px', borderRadius:'50px', fontWeight:700, border:'2px solid white' }}>
                              {
                              data.userBalanceData === undefined ? (
                                  <CircularProgress color="inherit" size={15} />
                                  )
                                  : formatearPrecio(data?.userBalanceData) ?? 0
                            } MXN
                          </Button>
                      </Tooltip>
                      <Tooltip title="Cerrar sesión">
                          <Button type="primary" icon={<LogoutOutlined />} onClick={() => logout()} danger style={{ borderRadius:'50px', padding:'20px', marginLeft:'20px', border: '2px solid white' }} />
                      </Tooltip>
                  </div>
              </div>
          </Header>
          <Content
            style={{
                margin: "-120px 16px 0",
                overflowY: "auto",
                padding: "0 20px",
            }}
          >
            {props.children}
          </Content>
          <Footer style={{ textAlign: 'center' }}>
            Drevfill © {new Date().getFullYear()} Created by Drevfill
          </Footer>
        </Layout>
      </Layout>
    </>
  );
};

export default CustomAppBar;
