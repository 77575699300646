import { Button, Card, Col, Form, Input, notification, Row, Select } from 'antd'
import React, {useEffect, useState, useContext, useCallback} from 'react'
import { GenerateShippingGuide } from '../../../api/OutboundShipmentsAPI'
import { QuoteDataContext } from '../../../context/QuoteDataContext'
import { useAuth0 } from '../../../hooks/react-auth0-spa'
import {axiosBase as axios} from "../../../api/AxiosConfig";
import {debounce} from "lodash";

export const estadosMexico = [
    { label: 'Aguascalientes', value: 'AG' },
    { label: 'Baja California', value: 'BC' },
    { label: 'Baja California Sur', value: 'BS' },
    { label: 'Campeche', value: 'CM' },
    { label: 'Chiapas', value: 'CS' },
    { label: 'Chihuahua', value: 'CH' },
    { label: 'Ciudad de México', value: 'CX' },
    { label: 'Coahuila', value: 'CO' },
    { label: 'Coahuila de Zaragoza', value: 'CO' },
    { label: 'Colima', value: 'CL' },
    { label: 'Durango', value: 'DG' },
    { label: 'Guanajuato', value: 'GT' },
    { label: 'Guerrero', value: 'GR' },
    { label: 'Hidalgo', value: 'HG' },
    { label: 'Jalisco', value: 'JA' },
    { label: 'Estado de México', value: 'EM' },
    { label: 'Michoacán', value: 'MI' },
    { label: 'Morelos', value: 'MO' },
    { label: 'Nayarit', value: 'NA' },
    { label: 'Nuevo León', value: 'NL' },
    { label: 'Oaxaca', value: 'OA' },
    { label: 'Puebla', value: 'PU' },
    { label: 'Querétaro', value: 'QT' },
    { label: 'Quintana Roo', value: 'QR' },
    { label: 'San Luis Potosí', value: 'SL' },
    { label: 'Sinaloa', value: 'SI' },
    { label: 'Sonora', value: 'SO' },
    { label: 'Tabasco', value: 'TB' },
    { label: 'Tamaulipas', value: 'TM' },
    { label: 'Tlaxcala', value: 'TL' },
    { label: 'Veracruz', value: 'VE' },
    { label: 'Yucatán', value: 'YU' },
    { label: 'Zacatecas', value: 'ZA' }
  ]

const FillGuideInfoForm = ({ token: userToken, shipment, userId, handleStep3, integrationOrder, updateOrdersData, destinationData, saveData }) => {

    const [ isLoading, setIsLoading ] = useState(false)
    const { quoteData, resetQuoteStepTransition } = useContext(QuoteDataContext)
    const [options, setOptions] = useState([])
    const [form] = Form.useForm()
    const { getTokenSilently } = useAuth0();

    const getSATProducts = async (param) => {
        try {
            const token = await getTokenSilently();
            const response = await axios.get(
                `https://prod.api-drenvio.com/v2/sat/products?text=${param}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (
                response.status !== 200 ||
                response.data.products.length === 0
            ) {
                setOptions([]);
                return;
            }

            const formattedOptions = response.data.products.map((item) => ({
                label: item.DESCRIPTION,
                value: item.PRODUCT_ID,
            }));

            setOptions(formattedOptions);
        } catch (error) {
            console.error("Error at getSATProducts: ", error);
        }
    };

    const handleSearch = useCallback(
        debounce((value) => {
            if (value) {
                getSATProducts(value);
            } else {
                setOptions([]);
            }
        }, 300),
        []
    );

    useEffect(() => {
        console.log('Options: ',options)
    },[options])

    // console.log('QuoteData ------------------------->', quoteData)
    // console.log('Destination Data ------------------>', destinationData)

    const onFinish = async(e) => {
        const token = await getTokenSilently()
        setIsLoading(true)
        const request = {
            "addressInfo": {
                "country": e.country,
                "postal_code": e.postal_code,
                "city": e.city,
                "company": e?.company?.trim() ?? "N/A",
                "district": e.district,
                "email": e.email,
                "name": e.name,
                "number": e.number,
                "phone": e.phone,
                "reference": e.reference,
                "state": e.state,
                "street": e.street
            },
            "shipmentInfo": {
                "carrier": quoteData.PackageData.Name,
                "contentExplanation": e.description,
                "contentQuantity": e.quantity,
                "price": quoteData.PackageData.Price,
                "service": quoteData.PackageData.Type,
                "shipmentType": quoteData.PackageData.Container,
                "service_id": quoteData.PackageData.Service_Id,
                "satContent": e.SATCode,
                "objectId": quoteData.PackageData.ObjectId,
                "carriers": [
                    quoteData.PackageData.Name
                ],
                "envioId": destinationData?.envioId ?? undefined
            },
            "integrationInfo": {
                isIntegration: Boolean(integrationOrder && integrationOrder?.fulfillment_info.status !== 'fulfilled'),
                order: integrationOrder,
                integration: saveData?.integrations && saveData?.integrations?.length >= 1 ? saveData?.integrations[0] : null
            },
            "customerInfo": {
                "customerId": userId
            },
            "productsInfo": shipment.InfoEnvioProductos.map(product => {
                return { productId: product.Producto, quantityToSend: product.CantidadEnviada }
            }) ,
            "packageInfo": quoteData.PackageArray.length > 0
            ? quoteData.PackageArray.map(pckg => {
                return {
                    "volumetric": Number((pckg.Height * pckg.Width * pckg.Length) / 5000),
                    "type": pckg.Container,
                    "content": e.SATCode,
                    "contentQuantity": 1,
                    "weight": pckg.Weight,
                    "height": pckg.Height,
                    "width": pckg.Width,
                    "length": pckg.Length,
                    "declared_value": e.price,
                    "name": `Paquete enviado a ${e.name}`,
                    "main_weight": pckg.Weight
                }
            })
            : [
                {
                    "volumetric": Number((quoteData.PackageData.Height * quoteData.PackageData.Width * quoteData.PackageData.Length) / 5000),
                    "type": quoteData.PackageData.Container,
                    "content": e.SATCode,
                    "contentQuantity": e.quantity,
                    "weight": quoteData.PackageData.Weight,
                    "height": quoteData.PackageData.Height,
                    "width": quoteData.PackageData.Width,
                    "length": quoteData.PackageData.Length,
                    "declared_value": e.price,
                    "name": `Paquete enviado a ${e.name}`,
                    "main_weight": quoteData.PackageData.Weight
                }
            ]
        }

        try {
            const response = await GenerateShippingGuide(token, request, userToken)
            // console.log(response.response)

            if(response.status !== 200){
                setIsLoading(false)
                return notification.error({ message: response?.response?.data?.msg || response?.data?.msg || response?.data?.errores || 'Error al generar envio' })
            }
            // console.log('----------------> response', response)
            if(response?.status === 200){
                console.log(response.data)
                notification.success({ message: 'Envío creado correctamente' })
                window.location.href = response?.data?.label
                form.resetFields()
                handleStep3()
                resetQuoteStepTransition('1) Quote based on Postal Codes')

                if (integrationOrder){
                    updateOrdersData()
                }
                return
            }
            setIsLoading(false)
        } catch (error) {
            // console.log('Error', error)
            setIsLoading(false)
            return notification.error({ message: 'Error al generar envio' })
        }
    }

    useEffect(() => {
        // Setear aqui los valores ya establecidos como direccion y demas.
        form.setFieldsValue({
            country: quoteData.AddressData.DestinyData.country,
            postal_code: quoteData.AddressData.DestinyData.postal_code,
            city: quoteData.AddressData.DestinyData.city,
            state: quoteData.AddressData.DestinyData.state,
            district: quoteData.AddressData.DestinyData.district,
            company: quoteData.AddressData.DestinyData.company ?? undefined,
            name: quoteData.AddressData.DestinyData.name ?? undefined,
            email: quoteData.AddressData.DestinyData.email ?? undefined,
            phone: quoteData.AddressData.DestinyData.phone ?? undefined,
            reference: quoteData.AddressData.DestinyData.reference ?? undefined,
            number: quoteData.AddressData.DestinyData.number ?? undefined,
            int_number: quoteData.AddressData.DestinyData.int_number ?? undefined,
            street: quoteData.AddressData.DestinyData.street ?? undefined,
            ...destinationData
        })
    }, [])

    const packageType = (type) => {
        switch (type) {
            case 'box':
                return 'Caja'
            case 'envelope':
                return 'Sobre'
            case 'pallet':
                return 'Tarima'
        }
    }

  return (
    <Row gutter={[10,10]}>
        <Col span={12}>
            <Form onFinish={onFinish} form={form}>
                <Card style={{ marginBottom:'10px', boxShadow:'0 0 10px 2px #0001' }}>
                    <h3 style={{ textAlign:'center' }}>Contenido</h3>
                    <Row gutter={[10,0]}>
                        <Col span={24}>
                            <Form.Item rules={[ { required: true, message: "Este campo es requerido" }, { min: 4, message: "Minimo 4 caracteres" }, { max: 20, message: "Maximo 20 caracteres" } ]} name='description' required>
                                <Input showCount minLength={4} maxLength={20} placeholder='Descripción del contenido*' />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item rules={[ { required: true, message: "Este campo es requerido" } ]} name='quantity' required>
                                <Input type='number' placeholder='Cantidad de articulos*' />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item rules={[ { required: true, message: "Este campo es requerido" } ]} name='price' required>
                                <Input type='number' placeholder='Valor del paquete (USD)*' />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                rules={[{ required: true, message: "Este campo es requerido" }]}
                                name="SATCode"
                                required
                            >
                                <Select
                                    filterOption={false}
                                    options={options}
                                    placeholder="Tipo de contenido*"
                                    showSearch
                                    onSearch={handleSearch}
                                    allowClear
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>
                <Card style={{ boxShadow:'0 0 10px 2px #0001' }}>
                    <h3 style={{ textAlign:'center' }}>Dirección del destino</h3>
                    <p>Contacto</p>
                    <Row gutter={[10,10]}>
                        <Col span={12}>
                            <Form.Item rules={[ { required: true, message: "Este campo requerido"}, { min: 4, message: "Minimo 4 caracteres" }, { max: 30, message: "Maximo 30 caracteres" } ]} name='name'>
                                <Input minLength={4} maxLength={30} showCount placeholder='Nombre' />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item rules={[ { required: true, message: "Este campo requerido"}, { min: 4, message: "Minimo 4 caracteres" }, { max: 32, message: "Maximo 32 caracteres" } ]} name='email'>
                                <Input minLength={4} maxLength={32} showCount placeholder='Correo' />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name='phone' rules={[ { required: true, message: "Este campo requerido"}, { min: 10, message: "Minimo 10 caracteres" }, { max: 10, message: "Maximo 10 caracteres" } ]}>
                                <Input minLength={10} maxLength={10} showCount placeholder='Teléfono' />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name='company' rules={[ { min: 3, required: true, message: "Minimo 4 caracteres" }, { max: 30, message: "Maximo 30 caracteres" } ]}>
                                <Input minLength={4} maxLength={30} showCount placeholder='Empresa' />
                            </Form.Item>
                        </Col>
                    </Row>
                    <p>Dirección</p>
                    <Row gutter={[10,10]}>
                        <Col span={12}>
                            <Form.Item name='street' rules={[ { required: true, message: "Este campo requerido"}, { min: 4, message: "Minimo 4 caracteres" }, { max: 32, message: "Maximo 32 caracteres" } ]}>
                                <Input minLength={4} maxLength={32} showCount placeholder='Calle' />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name='number' rules={[ { required: true, message: "Este campo requerido"}, { min: 1, message: "Minimo 1 caracter" }, { max: 14, message: "Maximo 14 caracteres" } ]}>
                                <Input minLength={1} maxLength={14} showCount placeholder='Número' />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name='int_number' rules={[ { min: 2, message: "Minimo 2 caracteres" }, { max: 14, message: "Maximo 14 caracteres" } ]}>
                                <Input minLength={2} maxLength={14} showCount placeholder='Número interior ( opcional )' />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name='district' rules={[ { required: true, message: "Este campo requerido"}, { min: 4, message: "Minimo 4 caracteres" }, { max: 32, message: "Maximo 32 caracteres" } ]}>
                                <Input minLength={2} maxLength={32} showCount placeholder='Colonia' />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name='city' rules={[ { required: true, message: "Este campo requerido"}, { min: 4, message: "Minimo 4 caracteres" }, { max: 50, message: "Maximo 50 caracteres" } ]}>
                                <Input minLength={4} maxLength={50} showCount placeholder='Delegación / Ciudad' />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name='postal_code' rules={[ { required: true, message: "Este campo requerido"} ]}>
                                <Input showCount disabled placeholder='Código Postal' />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name='country' rules={[ { required: true, message: "Este campo requerido"} ]}>
                                <Input showCount disabled placeholder='País' />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name='state' rules={[ { required: true, message: "Este campo requerido"} ]}>
                                <Select disabled={quoteData.AddressData.DestinyData.state ? true : false} options={estadosMexico} placeholder='Selecciona un estado' style={{ width:'100%' }} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name='reference' rules={[ { required: true, message: "Este campo requerido"}, { min: 4, message: "Minimo 4 caracteres" }, { max: 30, message: "Maximo 30 caracteres" } ]}>
                                <Input minLength={4} maxLength={30} showCount placeholder='Referencia' />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Button disabled={isLoading} type='primary' style={{ width:'100%', margin:'10px 0' }} htmlType='submit'>{ isLoading ? 'Generando ...' : 'GENERAR GUIA' }</Button>
                </Card>
            </Form>
        </Col>
        <Col span={12}>
            <Row gutter={[10,10]}>
                <Col span={24}>
                    <Card style={{ boxShadow:'0 0 10px 2px #0001' }}>
                        <Row gutter={[10,10]}>
                            <Col span={12}>
                                <h3>Información del envio</h3>
                                <div style={{ borderRadius:'5px', marginBottom:'5px' }}>
                                    <p style={{ margin:'0', padding:'0' }}>Paqueteria: {quoteData?.PackageData?.Name ?? "N/A"}</p>
                                    <p style={{ margin:'0', padding:'0' }}>Servicio: {quoteData?.PackageData?.Type ?? "N/A"}</p>
                                    <p style={{ margin:'0', padding:'0' }}>Tiempo: {quoteData.PackageData.Time}</p>
                                    <p style={{ margin:'0', padding:'0' }}>Precio: ${quoteData?.PackageData?.Price ?? "0"}MXN</p>
                                </div>
                            </Col>
                            <Col span={12}>
                                <h3>Información {quoteData.PackageArray.length > 1 ? 'de los paquetes' : 'del paquete'}</h3>
                                {
                                    quoteData.PackageArray.map(pckg => (
                                        <div style={{ borderRadius:'5px', marginBottom:'5px' }}>
                                            <hr />
                                            <p style={{ margin:'0', padding:'0' }}>Medidas { `${pckg.Width} x ${pckg.Height} x ${pckg.Length} x ${pckg.Weight}kg` }</p>
                                            <p style={{ margin:'0', padding:'0' }}>Tipo de paquete: {`${packageType(quoteData?.PackageData?.Container)}`}</p>
                                        </div>
                                    ))
                                }
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col span={12}>
                    <Card style={{ boxShadow:'0 0 10px 2px #0001' }}>
                        <h3>Productos</h3>
                        <Row gutter={[10,10]}>
                            <Col span={24}>
                                {
                                    shipment.InfoEnvioProductos.map(product => (
                                        <div style={{ borderRadius:'5px', marginBottom:'5px' }}>
                                            <p style={{ fontWeight:'600', margin:'0', padding:'0' }}>Producto: { product?.Nombre ?? "N/A" }</p>
                                            <p style={{ fontWeight:'600', margin:'0', padding:'0' }}>Cantidad a enviar: { product?.CantidadEnviada ?? "N/A" }</p>
                                            <p style={{ fontWeight:'600', margin:'0', padding:'0' }}>ID: { product?.Producto.slice(-12) ?? "N/A" }</p>
                                        </div>
                                    ))
                                }
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </Col>
    </Row>
  )
}

export default FillGuideInfoForm