import {useContext, useEffect, useState} from 'react'
import { useAuth0 } from "../../hooks/react-auth0-spa";
import Table from '../../components/tables/Table'
import { axiosBase as axios } from '../../api/AxiosConfig'
import { productsColumns } from '../../components/tables/schemaColumn'
import { productsDownloads } from '../../components/tables/schemaDownload'
import {ActivateProduct, getCustomFields} from "../../api/ProductAPI";
import {DataContext} from "../../context/dataContext";

const ITEMS_PER_PAGES = 150

const InactiveProducts = () => {
  const { getTokenSilently, user } = useAuth0();

  const [apiCustomFields, setApiCustomFields] = useState([]);

  const [rowsPerPages, setRowPerPages] = useState(20)
  const [currentPage, setCurrentPage] = useState(1)
  const [topLimit, setTopLimit] = useState(ITEMS_PER_PAGES)
  const [refetching, setIsRefetching] = useState(false)
  const [searchText, setSearchText] = useState('') // string
  const [dataColumns, setDataColumns] = useState(null) // any 
  const [activateDelete, setActivateDelete] = useState(false)
  const { setInventoryId } = useContext(DataContext)
  const [downloadColumns] = useState({
    range: {
      from: 0,
      to: topLimit
    },
    searched: searchText,
    isAdmin: false,
    columns: [
      {
        id: 'SKUCliente',
        find: false
      },
      {
        id: 'Nombre',
        find: false
      },
      {
        id: 'Descripcion',
        find: false
      },
      {
        id: 'ValorMercancia',
        find: false
      },
      {
        id: 'PesoKG',
        find: false
      },
      {
        id: 'Largo',
        find: false
      },
      {
        id: 'Ancho',
        find: false
      },
      {
        id: 'Altura',
        find: false
      },
      {
        id: 'Visible',
        find: true,
        filters: {
          sort: false,
          filter: true,
          filter_values: [false] 
        }
      },
      
    ]
  })
  const [filterByColumns, setFilterByColumns] = useState({ ...downloadColumns })

  const handleReload = async() => {
    setActivateDelete(!activateDelete)
  }

  const handleFecthing = async (filterByColumns, saveState = true) => {
    const userToken = await getTokenSilently()
    const { data } = await axios.post(
        `/allproducts`, 
        filterByColumns, 
        { headers: { Authorization: `Bearer ${userToken}` }}
    )

    if (saveState){
      setDataColumns(data)
    } else {
      return data ?? []
    }
  }

  useEffect(() => {
    if (!dataColumns?.length) return
    const totalPages = (dataColumns?.length ?? 0) / rowsPerPages

    if (currentPage + 1 < Math.round(totalPages)) return
    setTopLimit((prevState) => prevState + ITEMS_PER_PAGES)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]) 

  useEffect(() => {
    (async () => {
      if (topLimit === ITEMS_PER_PAGES) return
      setFilterByColumns({ ...filterByColumns, range: { ...filterByColumns.range, to: topLimit } })
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topLimit])

  const getAllCustomFields = async () => {
    const token = await getTokenSilently();
    const response = await getCustomFields(token, user.sub, "default");
    if (response.status === 200) {
      setApiCustomFields(response.data.CustomFields);
    }
  };

  const productActivate = async(productId) => {
    const token = await getTokenSilently()
    await ActivateProduct(token, productId);
    await handleFecthing(filterByColumns)
  }

  useEffect(() => {
    setInventoryId("default")
  }, []);//eslint-disable-line

  useEffect(() => {
    (async () => {
      setIsRefetching(true)
      await handleFecthing(filterByColumns)
      await getAllCustomFields()
      setIsRefetching(false)
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterByColumns, activateDelete]) 

  return (
    <Table
      // title={"Productos"}
      tableType="products"
      data={dataColumns ?? []}
      isLoading={refetching}
      onChangePage={(page) => setCurrentPage(page)}
      onChangeRowsPerPage={(row) => setRowPerPages(row)}
      schemaDownload={productsDownloads}
      columns={productsColumns("inactive", handleReload, apiCustomFields, productActivate) ?? []}
      config={{ disableFilters: true }}
      reload
      handleReload={(async() => await handleFecthing(filterByColumns))}
      stateValues={{
        currentPage,
        rowsPerPages,
        topLimit,
        searchText,
        filterByColumns,
        downloadColumns,
        newProspectModal: false,
      }}
      filters={{
        filters: ['date'],
        filterBySearchText(text) {
          setSearchText(text)
        },
        filterByColumns(filter) {
          setFilterByColumns(filter)
        },
        filterDownloads: async (filter) => {
          return await handleFecthing(filter, false)
        },
      }}
    />
  )
}

export default InactiveProducts