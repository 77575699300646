import ColumnActionsUserProducts from "../../pages/products/ColumnActions"
import ColumnActionsAdminProducts from "../../pages/warehouseProducts/ColumnActions"
import ColumnActionsUpdateProducts from "../../pages/updateProducts/ColumnsActionsUpdateProducts"
import DetailsChanges from "../../pages/updateProducts/DetailsChanges"
import { OutboundShipmentStates, WarehouseShipmentStates } from "../../utils/States";
import { MovementsStates } from "../../utils/States";
import { format } from "date-fns";
import ColumnActions from "../../pages/warehouseShipping/ColumnActions";
import RateModal from '../../pages/manualDispatch/RateModal'
import { Grid } from "@material-ui/core";
import {Button, Col, Row, Tooltip} from "antd";
import VisibilityIcon from "@material-ui/icons/Visibility";
import InfoMovements from "../../pages/tracking/InfoMovements";
import { ArrowRightOutlined } from "@ant-design/icons";

export const productsColumns = (state, handleReload, customFields, productActivate) => {

    const baseColumns = [
        {
            title: 'Fotografia',
            dataIndex: '',
            align: 'center',
            render: (product)=> (
                <img
                    src={product.Fotografia}
                    alt={product.Nombre}
                    width="80"
                    height="80"
                />
            )
        },
        {
            title: "SKU",
            dataIndex: 'SKUCliente',
            align: 'center',
        },
        {
            title: "Nombre",
            dataIndex: "Nombre",
            align: 'center',
        },
        {
            title: "Descripcion",
            dataIndex: "Descripcion",
            align: 'center',
        },
        {
            title: "Cant. en inventario",
            dataIndex: "CantidadEnInventario",
            align: 'center',
            showSorterTooltip: false,
            sorter: (a, b) => a.CantidadEnInventario - b.CantidadEnInventario,
        },
        {
            title: (<p>Ventas <br /> <span style={{ color:'rgba(0,0,0,0.52)', fontSize:'12px', lineBreak:'100px' }}>últ. 30 días</span></p>),
            dataIndex: "",
            align: "center",
            render: (rowData) => (<p>{ rowData?.totalShipmentsThisMonth ?? 0 } u.</p>)
        },
        {
            title: "Costo",
            dataIndex: "",
            align: "center",
            render: (obj) => `${obj?.ValorMercancia ? `$${obj.ValorMercancia}` : '$0'}`
        },
        {
            title: "Dimensiones ",
            dataIndex: "",
            align: 'center',
            render: (obj) => `${obj.Largo} x ${obj.Ancho} x ${obj.Altura}`
        },
        {
            title: "Peso ",
            dataIndex: "PesoKG",
            align: 'center',
            showSorterTooltip: false,
            sorter: (a, b) => a.PesoKG - b.PesoKG,
            render: (PesoKG) => `${PesoKG} Kg.`
        },
    ]

    const formattedCustomFields = customFields.map((item) => {
        if(item.visible){
            return {
                title: item?.fieldName,
                dataIndex: "",
                align: "center",
                render: (rowData) => `${rowData?.CustomFields && rowData?.CustomFields[item?.fieldName] || ""}`
            };
        }
    }).filter(item => item !== undefined)


    console.log('Custom fields formateados ------>', formattedCustomFields)

    if (state === 'active'){
        formattedCustomFields.push({
            title:"Acciones",
            align:"center",
            dataIndex: "",
            render: (data) => {
                return <ColumnActionsUserProducts data={data} handleReload={handleReload}  />
            }
        })
    }

    if(state === 'inactive' && productActivate){
        formattedCustomFields.push({
            title: "Acciones",
            align: "center",
            dataIndex: "",
            render: (data) => {
                return <Button onClick={() => productActivate(data?._id)}>Activar</Button>
            }
        })
    }

    const newColums = [...baseColumns, ...formattedCustomFields];

    return newColums
}

export const customWarehouseProductsColumns = (state, handleReload, customFields, productActivate, type) => {

    const baseColumns = [
        {
            title: 'Fotografia',
            dataIndex: '',
            align: 'center',
            render: (product)=> (
                <img
                    src={product.Fotografia}
                    alt={product.Nombre}
                    width="80"
                    height="80"
                />
            )
        },
        {
            title: "SKU",
            dataIndex: 'SKUCliente',
            align: 'center',
        },
        {
            title: "Nombre",
            dataIndex: "Nombre",
            align: 'center',
        },
        {
            title: "Descripcion",
            dataIndex: "Descripcion",
            align: 'center',
        },
        {
            title: "Cant. en inventario",
            dataIndex: "CantidadEnInventario",
            align: 'center',
            showSorterTooltip: false,
            sorter: (a, b) => a.CantidadEnInventario - b.CantidadEnInventario,
        },
        {
            title: "Costo",
            dataIndex: "",
            align: "center",
            render: (obj) => `${obj?.ValorMercancia ? `$${obj.ValorMercancia}` : '$0'}`
        },
        {
            title: "Dimensiones ",
            dataIndex: "",
            align: 'center',
            render: (obj) => `${obj.Largo} x ${obj.Ancho} x ${obj.Altura}`
        },
        {
            title: "Peso ",
            dataIndex: "PesoKG",
            align: 'center',
            showSorterTooltip: false,
            sorter: (a, b) => a.PesoKG - b.PesoKG,
            render: (PesoKG) => `${PesoKG} Kg.`
        },
    ]

    const formattedCustomFields = customFields.map((item) => {
        if(item.visible){
            return {
                title: item?.fieldName,
                dataIndex: "",
                align: "center",
                render: (rowData) => `${rowData?.CustomFields && rowData?.CustomFields[item?.fieldName] || ""}`
            };
        }
    }).filter(item => item !== undefined)

    if (state === 'active'){
        formattedCustomFields.push({
            title:"Acciones",
            align:"center",
            dataIndex: "",
            render: (data) => {
                return <ColumnActionsUserProducts data={data} handleReload={handleReload} type={type ?? "default"} />
            }
        })
    }

    if(state === 'inactive' && productActivate){
        formattedCustomFields.push({
            title: "Acciones",
            align: "center",
            dataIndex: "",
            render: (data) => {
                return <Button onClick={() => productActivate(data?._id)}>Activar</Button>
            }
        })
    }

    const newColums = [...baseColumns, ...formattedCustomFields];

    return newColums
}

export const warehouseProductsColumn = (state, handleFecthing, filterByColumns) => {

    const baseColumns = [
        {
            title: 'Fotografia',
            dataIndex: '',
            align: 'center',
            render: (product)=> (
                <img
                    src={product.Fotografia}
                    alt={product.Nombre}
                    width="80"
                    height="80"
                />
            )
        },
        {
            title: "SKU",
            dataIndex: 'SKUCliente',
            align: 'center',
        },
        {
            title: "Nombre",
            dataIndex: "Nombre",
            align: 'center',
        },
        {
            title: "Propietario",
            dataIndex: "",
            align: 'center',
            render: (obj) => obj?.user?.email || 'NA'
        },
        {
            title: "Descripcion",
            dataIndex: "Descripcion",
            align: 'center',
        },
        {
            title: "Cant. Inventario",
            dataIndex: "CantidadEnInventario",
            align:"center",
            showSorterTooltip: false,
            sorter: (a, b) => a.CantidadEnInventario - b.CantidadEnInventario,
        },
        {
            title: (<p>Ventas <br /> <span style={{ color:'rgba(0,0,0,0.52)', fontSize:'12px', lineBreak:'100px' }}>últ. 30 días</span></p>),
            dataIndex: "",
            align: "center",
            render: (rowData) => (<p>{ rowData?.totalShipmentsThisMonth ?? 0 } u.</p>)
        },
        {
            title: "ValorMercancia",
            dataIndex: "ValorMercancia",
            align: 'center',
            showSorterTooltip: false,
            sorter: (a, b) => a.ValorMercancia - b.ValorMercancia,
            render: (price) => `${price}`
        },
        {
            title: "Dimensiones ",
            dataIndex: "",
            align: 'center',
            render: (obj) => `${obj.Largo} x ${obj.Ancho} x ${obj.Altura}`
        },
        {
            title: "Peso ",
            dataIndex: "PesoKG",
            align: 'center',
            showSorterTooltip: false,
            sorter: (a, b) => a.PesoKG - b.PesoKG,
            render: (PesoKG) => `${PesoKG} Kg.`
        },
    ]

    if (state === 'active'){
        baseColumns.push({
            title: "Acciones",
            dataIndex: "",
            align:"center",
            render: (data) => {
                return (
                    <ColumnActionsAdminProducts data={data} refetch={handleFecthing} filterByColumns={filterByColumns} />
                )
            }
        })
    }

    return baseColumns
}

export const warehouseShipmentsColumns = (status, setIsRefetching) => {
    
    return [
        {
            title: "Codigo envio",
            dataIndex: "",
            align: 'center',
            render: (data) => (
                <p>{ data?.EnvioId?.slice(-12) }</p>
            )
        },
        {
            title: "Estatus",
            dataIndex: "",
            align: 'center',
            render: (data) => (
                <p style={{ display:'grid', justifyItems:'center' }}>{WarehouseShipmentStates(data.Estatus)}</p>
            )
        },
        {
            title: "Fecha de envio",
            dataIndex: "",
            align: "center",
            render: (data) => (
                <p>
                    {format(
                        new Date(data.FechaEnvio),
                        "dd/MM/yyyy HH:MM:SS"
                    ) || ""}
                </p>
            )
        },
        {
            title: "Fecha de Recepción",
            dataIndex: "",
            align: "center",
            render: (data) => (
                <p>
                    {data.FechaRecepcion
                        ? format(
                            new Date(data?.FechaRecepcion),
                            "dd/MM/yyyy HH:MM:SS"
                        )
                        : (
                            <p>Sin recibir</p>
                        )
                    }
                </p>
            )
        },
        {
            title: "Guía",
            dataIndex: "",
            align: "center",
            render: (data) => (
                <p>
                    {
                        data?.Guia?.ProveedorDeGuia
                        ? `${data?.Guia?.ProveedorDeGuia} - ${data?.Guia?.NumeroDeGuia}`
                        : "Entrega personal"
                    }
                </p>
            )
        },
        {
            title: "Acciones",
            dataIndex: "",
            align:"center",
            render: (data) => (
                <ColumnActions data={data} status={status} setIsRefetching={setIsRefetching} />
            )
        }
    ]
}

export const updateProducts = (handleReload) => {

    return [
        {
            title: "SKU",
            dataIndex: 'SKUFulfillment',
            align: 'center',
            render: (value) => `${value.toString().slice(-12)}`
        },
        {
            title: "Cant. Inventario",
            dataIndex: "CantidadEnInventario",
            align:"center",
        },
        {
            title: "Detalles de cambios",
            dataIndex: "",
            align:"center",
            render: (data)=> <DetailsChanges data={data} />
        },
        {
            title: "Acciones",
            dataIndex: "",
            align:"center",
            render: (data) => {
                return (
                    <ColumnActionsUpdateProducts data={data} handleReload={handleReload}/>
                )
            }
        }
    ]
}
        
export const movementsColumns = () => {

    return [
        {
            title: "Usuario",
            dataIndex: "",
            align:"center",
            render: (data) => (
                <p>{ data?.customer?.email ?? "NA" }</p>
            )
        },
        {
            title: "Tipo",
            dataIndex: "",
            align:"center",
            render: (data) => (
                <p style={{ display:'grid', justifyItems:'center' }}>{ MovementsStates(data?.type) ?? "N/A" }</p>
            )
        },
        {
            title: "Descripción",
            dataIndex: "",
            align:"center",
            render: (data) => (
                <p>{ data?.description ?? "NA" }</p>
            )
        },
        {
            title: "Fecha",
            dataIndex: "",
            align:"center",
            render: (data) => (
                <p>{format(
                    new Date(data.created_at),
                    "dd/MM/yyyy HH:MM:SS"
                ) || ""}</p>
            )
        },
        {
            title: "Cant. Anterior",
            dataIndex: "",
            align:"center",
            render: (data) => (
                <p>{ data?.metadata?.before_mod ?? "NA" }</p>
            )
        },
        {
            title: "Cant. Posterior",
            dataIndex: "",
            align:"center",
            render: (data) => (
                <p>{ data?.metadata?.after_mod ?? "NA" }</p>
            )
        },
        {
            title: "Realizado por",
            dataIndex: "",
            align:"center",
            render: (data) => (
                <p>{ data?.user?.email ?? "NA" }</p>
            )
        },
        {
            title: "Acciones",
            dataIndex: "",
            align: "center",
            render: (data) => (
                <Row>
                    <Col span={24}>
                        <InfoMovements data={data} />
                    </Col>
                </Row>
            )
        }
    ]
}

export const outboundShipments = (setOpenModal) => {

    return [
        {
            title: "Codigo envio",
            dataIndex:"",
            align:"center",
            render: (data) => (
                <p>{ data?.EnvioId?.slice(-12) }</p>
            )
        },
        {
            title: "Estatus",
            dataIndex: "",
            align: "center",
            render: (data) => (
                <p style={{ display:'grid', justifyItems:'center' }}>{OutboundShipmentStates(data.Estatus)}</p>
            )
        },
        {
            title: "Fecha envio",
            dataIndex: "",
            align: "center",
            render: (data) => (
                format(
                    new Date(data?.FechaEnvio),
                    "dd/MM/yyyy HH:MM:SS"
                )
            )
        },
        {
            title: "Acciones",
            dataIndex: "",
            align: "center",
            render: (data) => {
                return (
                    <Grid style={{ justifyContent:'center' }} className="flex" container>
                        <Tooltip title="Ver detalles">
                            <VisibilityIcon style={{ cursor:'pointer' }} onClick={() => setOpenModal({ data, open: true })} />
                        </Tooltip>
                    </Grid>
                )
            }
        }
    ]
}

export const outboundShipmentsAdmin = (setOpenModal) => {

    return [
        {
            title: "Codigo envio",
            dataIndex:"",
            align:"center",
            render: (data) => (
                <p>{ data?.envioId?.slice(-12) }</p>
            )
        },
        {
            title: "Cliente",
            dataIndex: "",
            align: "center",
            render: (data) => (
                <p>{ data?.email ?? "NA" }</p>
            )
        },
        {
            title: "Estatus",
            dataIndex: "",
            align: "center",
            render: (data) => (
                <p style={{ display:'grid', justifyItems:'center' }}>{OutboundShipmentStates(data.estatus)}</p>
            )
        },
        {
            title: "Fecha envio",
            dataIndex: "",
            align: "center",
            render: (data) => (
                format(
                    new Date(data?.fechaEnvio),
                    "dd/MM/yyyy HH:MM:SS"
                )
            )
        },
        {
            title: "Acciones",
            dataIndex: "",
            align: "center",
            render: (data) => {
                return (
                    <Grid style={{ justifyContent:'space-evenly' }} className="flex" container>
                        <Tooltip title="Ver detalles">
                            <VisibilityIcon style={{ cursor:'pointer' }} onClick={() => setOpenModal({ data, open: true })} />
                        </Tooltip>
                        <Tooltip title={data.ApiClientsV2.length === 0 ? "ASIGNALE TOKEN A ESTE CLIENTE PARA PODER PROCESAR SU ORDEN" : "Procesar orden"}>
                            <RateModal 
                                text="Cotizar"
                                isFlexible={true}
                                data={data} 
                                integrationOrder={null}
                            />
                        </Tooltip>
                    </Grid>
                )
            }
        }
    ]
}


export const manualDispatch = () => {

    return [
        {
            title: "Fecha",
            dataIndex: ['fulfill', 'createdAt'],
            align: 'center',
            render: (date) => `${new Date(date).toLocaleString()}`
        },
        {
            title: "Nombre",
            dataIndex: "name",
            align:"center",
        },
        {
            title: "Email",
            dataIndex: "email",
            align:"center",
        },
        {
            title: "Acciones",
            dataIndex: "",
            align: 'center',
            render: (rowData) => (
                <RateModal
                    text="Cotizar"
                    isFlexible={true}
                    data={rowData} 
                    integrationOrder={null}
                />
            ) 
        },
    ]
}

export const usersByIntegration = (setModal, modal) => {

    return [
        {
            title: "Usuario",
            dataIndex: "name",
            align:"center",
        },
        {
            title: "Email",
            dataIndex: "email",
            align: "center",
        },
        {
            title: "Empresa",
            dataIndex: "company",
            align:"center",
        },
        {
            title: "Acciones",
            dataIndex: "",
            align: "center",
            render: (rowData) => (<Button icon={<ArrowRightOutlined />} onClick={()=>setModal({ data: rowData, open: true})}>Ver tiendas</Button>)
        }
    ]
}
