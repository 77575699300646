import React from 'react';
import Mercadolibre from "./Mercadolibre";
import CustomWarehouse from "./CustomWarehouse/CustomWarehouse";

function SelectIntegration({ type, inventoryId }) {
    switch (type){
        case 'MERCADOLIBRE':
            return (
                <Mercadolibre />
            )
        case 'AMAZON':
            return (
                <p>Amazon</p>
            )
        case 'CUSTOM_WAREHOUSE':
            return <CustomWarehouse inventoryId={inventoryId} />
        default:
            return (
                <p>Na</p>
            )
    }
}

export default SelectIntegration;