// Logos de las Paqeterías (Imágenes)
import estafeta from '../../../images/parcelLogos/estafeta.png'
import redpack from '../../../images/parcelLogos/redpack.png'
import carssa from '../../../images/parcelLogos/carssa.png'
import quiken from '../../../images/parcelLogos/quiken.png'
import scm from '../../../images/parcelLogos/scm.png'
import ampm from '../../../images/parcelLogos/ampm.png'
import sendex from '../../../images/parcelLogos/sendex.png'
import dhl from '../../../images/parcelLogos/dhl.png'
import ivoy from '../../../images/parcelLogos/ivoy.png'
import fedex from '../../../images/parcelLogos/fedex.png'
import mensajerosUrbanos from '../../../images/parcelLogos/mensajerosUrbanos.png'
import noventa9minutos from '../../../images/parcelLogos/noventa9minutos.png'
import paquetexpress from '../../../images/parcelLogos/paquetexpress.png'
import uber from '../../../images/parcelLogos/uber.png'
import testDrenvio from '../../../images/parcelLogos/testDrenvio.png'
import ups from '../../../images/parcelLogos/ups.png'
import coordinadora from '../../../images/parcelLogos/coordinadora.png'
import interRapidisimo from '../../../images/parcelLogos/Interrapidisimo-Gris.png'
import redServi from '../../../images/parcelLogos/redServi.png'
import serviEntrega from '../../../images/parcelLogos/serviEntrega.png'
import deprisa from '../../../images/parcelLogos/Deprisa-Morado.png'
import interrapidisimo from '../../../images/parcelLogos/Interrapidisimo-Morado.png'
import interrapidisimo2 from '../../../images/parcelLogos/interrapidisimo-logo.png'
import interrapidisimo_v2 from '../../../images/parcelLogos/Inter-Rapidisimo.png'
import aExpress from '../../../images/parcelLogos/aExpress.png'
import jtexpress from '../../../images/parcelLogos/jtexpress.png'
import borzo from '../../../images/parcelLogos/borzo.png'
import treguerras from '../../../images/parcelLogos/tresguerras.png'
import treggo from '../../../images/parcelLogos/treggo.png'
import pickit from '../../../images/parcelLogos/pickit.png'
import moova from '../../../images/parcelLogos/moova.png'
import olva from '../../../images/parcelLogos/olva.png'
import nirex from '../../../images/parcelLogos/nirex.png'
import enviaco from '../../../images/parcelLogos/enviaco.png'
import picap from '../../../images/parcelLogos/picap.png'
//Carries dashboard logos
import estafetaDashboard from '../../../images/parcelDashboard/estafeta.webp'
import redpackDashboard from '../../../images/parcelDashboard/redpack.webp'
import carssaDashboard from '../../../images/parcelDashboard/carssa.webp'
import quikenDashboard from '../../../images/parcelDashboard/quiken.webp'
import scmDashboard from '../../../images/parcelDashboard/scm.webp'
import ampmDashboard from '../../../images/parcelDashboard/ampm.webp'
import sendexDashboard from '../../../images/parcelDashboard/sendex.webp'
import dhlDashboard from '../../../images/parcelDashboard/dhl.webp'
import ivoyDashboard from '../../../images/parcelDashboard/ivoy.webp'
import fedexDashboard from '../../../images/parcelDashboard/fedex.webp'
import mensajerosUrbanosDashboard from '../../../images/parcelDashboard/mensajerosUrbanos.webp'
import noventa9minutosDashboard from '../../../images/parcelDashboard/noventa9minutos.webp'
import paquetexpressDashboard from '../../../images/parcelDashboard/paquetexpress.webp'
import uberDashboard from '../../../images/parcelDashboard/uber.webp'
import upsDashboard from '../../../images/parcelDashboard/ups.webp'
import jtexpressDashboard from '../../../images/parcelDashboard/jtexpress.webp'
import borzoDashboard from '../../../images/parcelDashboard/borzo.webp'
import treguerrasDashboard from '../../../images/parcelDashboard/tresguerras.webp'
import redserviDashboard from '../../../images/parcelDashboard/RedSErvi.png'
import aexpressDashboard from '../../../images/parcelDashboard/aexpress.png'
import coordinadoraDashboard from '../../../images/parcelDashboard/coordina.png'
import servientregaDashboard from '../../../images/parcelDashboard/servientrega.png'
import interrapidisimoDashboard from '../../../images/parcelDashboard/interrapidisimo.png'
import treggoDashboard from '../../../images/parcelDashboard/treggo.webp'
import pickitDashboard from '../../../images/parcelDashboard/pickit.png'
import moovaDashboard from '../../../images/parcelDashboard/moova.webp'
import olvaDashboard from '../../../images/parcelDashboard/olva.webp'
import nirexDashboard from '../../../images/parcelDashboard/nirex.webp'
import enviacoDashboard from '../../../images/parcelDashboard/enviaco.webp'
import picapDashboard from '../../../images/parcelDashboard/picap.webp'
// Mapa con todas las paqueterías y sus características
// Nota#1 ---> El archivo es .tsx debido a que se ponen <img/> con estilos específicos de cada imágen (para que se vea mejor)
// Nota#2 ---> Si quieres agregar una nueva paquetería solo agrega su nombre y la información necesaria

export const parcel_map = {
  test: 'Paqueteria Drenvion TEST',
  fedex: 'Fedex',
  sendex: 'Sendex',
  dhl: 'DHL',
  ups: 'UPS',
  redpack: 'Redpack',
  carssa: 'Carssa',
  ivoy: 'iVoy',
  scm: 'SCM',
  quiken: 'Quiken',
  ampm: 'AMPM',
  estafeta: 'Estafeta',
  noventa9Minutos: '99 Minutos',
  mensajerosUrbanos: 'Mensajeros Urbanos',
  uber: 'Uber',
  aExpress: 'AExpress',
  coordinadora: 'Coordinadora',
  interRapidisimo: 'InterRapidisimo',
  redServi: 'Redservi',
  serviEntrega: 'ServiEntrega',
  deprisa: 'Deprisa',
  vencedor: 'Vencedor',
  paquetexpress: 'Paquetexpress',
  jtexpress: 'J&T express',
  borzo: 'Borzo',
  interrapidisimo: 'Interrapidisimo',
  tresguerras: 'Tres guerras',
  treggo: 'Treggo',
  pickit: 'Pickit',
  moova: 'Moova',
  olva: 'Olva',
  nirex: 'Nirex',
  envia: 'Envia',
  picap: 'Picap',
}

export const parcel_comercial_name = (key) => {
  return parcel_map[key]
}

export const allParcels = new Map([])

allParcels.set('test', {
  imgSrc: testDrenvio,
  activated: true,
  image: (
    <img
      alt="testDrenvio"
      src={testDrenvio}
      style={{
        width: '70%',
        height: '70%',
        position: 'absolute'
      }}
    />
  ),
  comercialName: parcel_comercial_name('test'),
  dashboardImageSrc: fedexDashboard
})
allParcels.set('fedex', {
  imgSrc: fedex,
  activated: true,
  image: (
    <img
      alt="fedex"
      src={fedex}
      style={{
        width: 'auto',
        height: '70%',
        position: 'absolute'
      }}
    />
  ),
  comercialName: parcel_comercial_name('fedex'),
  dashboardImageSrc: fedexDashboard
})
allParcels.set('dhl', {
  imgSrc: dhl,
  activated: true,
  image: (
    <img
      alt="dhl"
      src={dhl}
      style={{
        width: 'auto',
        height: '75%',
        position: 'absolute'
      }}
    />
  ),
  comercialName: parcel_comercial_name('dhl'),
  dashboardImageSrc: dhlDashboard
})
allParcels.set('estafeta', {
  imgSrc: estafeta,
  activated: true,
  image: (
    <img
      alt="estafeta"
      src={estafeta}
      style={{
        width: 'auto',
        height: '70%',
        position: 'absolute'
      }}
    />
  ),
  comercialName: parcel_comercial_name('estafeta'),
  dashboardImageSrc: estafetaDashboard
})
allParcels.set('redpack', {
  imgSrc: redpack,
  activated: true,
  image: (
    <img
      alt="redpack"
      src={redpack}
      style={{
        width: 'auto',
        height: '75%',
        position: 'absolute'
      }}
    />
  ),
  comercialName: parcel_comercial_name('redpack'),
  dashboardImageSrc: redpackDashboard
})
allParcels.set('carssa', {
  imgSrc: carssa,
  activated: true,
  image: (
    <img
      alt="carssa"
      src={carssa}
      style={{
        width: 'auto',
        height: '75%',
        position: 'absolute'
      }}
    />
  ),
  comercialName: parcel_comercial_name('carssa'),
  dashboardImageSrc: carssaDashboard
})
allParcels.set('quiken', {
  imgSrc: quiken,
  activated: true,
  image: (
    <img
      alt="quiken"
      src={quiken}
      style={{
        width: 'auto',
        height: '75%',
        position: 'absolute'
      }}
    />
  ),
  comercialName: parcel_comercial_name('quiken'),
  dashboardImageSrc: quikenDashboard
})
allParcels.set('scm', {
  imgSrc: scm,
  activated: true,
  image: (
    <img
      alt="scm"
      src={scm}
      style={{
        width: 'auto',
        height: '75%',
        position: 'absolute'
      }}
    />
  ),
  comercialName: parcel_comercial_name('scm'),
  dashboardImageSrc: scmDashboard
})
allParcels.set('sendex', {
  imgSrc: sendex,
  activated: true,
  image: (
    <img
      alt="sendex"
      src={sendex}
      style={{
        width: 'auto',
        height: '75%',
        position: 'absolute'
      }}
    />
  ),
  comercialName: parcel_comercial_name('sendex'),
  dashboardImageSrc: sendexDashboard
})
allParcels.set('ampm', {
  imgSrc: ampm,
  activated: true,
  image: (
    <img
      alt="ampm"
      src={ampm}
      style={{
        width: 'auto',
        height: '75%',
        position: 'absolute'
      }}
    />
  ),
  comercialName: parcel_comercial_name('ampm'),
  dashboardImageSrc: ampmDashboard
})
allParcels.set('ivoy', {
  imgSrc: ivoy,
  activated: true,
  image: (
    <img
      alt="ivoy"
      src={ivoy}
      style={{
        width: 'auto',
        height: '75%',
        position: 'absolute'
      }}
    />
  ),
  comercialName: parcel_comercial_name('ivoy'),
  dashboardImageSrc: ivoyDashboard
})
allParcels.set('mensajerosUrbanos', {
  imgSrc: mensajerosUrbanos,
  activated: true,
  image: (
    <img
      alt="mensajerosUrbanos"
      src={mensajerosUrbanos}
      style={{
        width: 'auto',
        height: '60%',
        position: 'absolute'
      }}
    />
  ),
  comercialName: parcel_comercial_name('mensajerosUrbanos'),
  dashboardImageSrc: mensajerosUrbanosDashboard
})
allParcels.set('noventa9Minutos', {
  imgSrc: noventa9minutos,
  activated: true,
  image: (
    <img
      alt="noventa9minutos"
      src={noventa9minutos}
      style={{
        width: 'auto',
        height: '50%',
        position: 'absolute'
      }}
    />
  ),
  comercialName: parcel_comercial_name('noventa9Minutos'),
  dashboardImageSrc: noventa9minutosDashboard
})
allParcels.set('paquetexpress', {
  imgSrc: paquetexpress,
  activated: true,
  image: (
    <img
      alt="paquetexpress"
      src={paquetexpress}
      style={{
        width: 'auto',
        height: '75%',
        position: 'absolute'
      }}
    />
  ),
  comercialName: parcel_comercial_name('paquetexpress'),
  dashboardImageSrc: paquetexpressDashboard
})
//para que no se vea tracusa en el front
/*allParcels.set('tracusa', {
  imgSrc: tracusa,
  activated: true,
  image: (
    <img
      alt="tracusa"
      src={tracusa}
      style={{
        width: 'auto',
        height: '75%',
        position: 'absolute'
      }}
    />
  ),
  dashboardImageSrc: tracusaDashboard
})*/
allParcels.set('uber', {
  imgSrc: uber,
  activated: true,
  image: (
    <img
      alt="uber"
      src={uber}
      style={{
        width: 'auto',
        height: '75%',
        position: 'absolute'
      }}
    />
  ),
  comercialName: parcel_comercial_name('uber'),
  dashboardImageSrc: uberDashboard
})
allParcels.set('ups', {
  imgSrc: ups,
  activated: true,
  image: (
    <img
      alt="ups"
      src={ups}
      style={{
        width: 'auto',
        height: '75%',
        position: 'absolute'
      }}
    />
  ),
  comercialName: parcel_comercial_name('ups'),
  dashboardImageSrc: upsDashboard
})
allParcels.set('aExpress', {
  imgSrc: aExpress,
  activated: true,
  image: (
    <img
      alt="aExpress"
      src={aExpress}
      style={{
        width: 'auto',
        height: '77%',
        position: 'absolute'
      }}
    />
  ),
  comercialName: parcel_comercial_name('aExpress'),
  dashboardImageSrc: aexpressDashboard
})
allParcels.set('coordinadora', {
  imgSrc: coordinadora,
  activated: true,
  image: (
    <img
      alt="coordinadora"
      src={coordinadora}
      style={{
        width: '100%',
        height: '100%',
        position: 'absolute'
      }}
    />
  ),
  comercialName: parcel_comercial_name('coordinadora'),
  dashboardImageSrc: coordinadoraDashboard
})
allParcels.set('interRapidisimo', {
  imgSrc: interRapidisimo,
  activated: true,
  image: (
    <img
      alt="interrapidisimo"
      // * IMG COTIZAR CONTRA ENTREGA COLOMBIA
      src={interrapidisimo_v2}
      style={{
        width: '100%',
        height: '100%',
        position: 'absolute'
      }}
    />
  ),
  comercialName: parcel_comercial_name('interRapidisimo'),
  dashboardImageSrc: interRapidisimo
})
allParcels.set('redServi', {
  imgSrc: redServi,
  activated: true,
  image: (
    <img
      alt="redServi"
      src={redServi}
      style={{
        width: 'auto',
        height: '80%',
        position: 'absolute'
      }}
    />
  ),
  comercialName: parcel_comercial_name('redServi'),
  dashboardImageSrc: redserviDashboard
})
allParcels.set('serviEntrega', {
  imgSrc: serviEntrega,
  activated: true,
  image: (
    <img
      alt="serviEntrega"
      src={serviEntrega}
      style={{
        width: '100%',
        height: '100%',
        position: 'absolute'
      }}
    />
  ),
  comercialName: parcel_comercial_name('serviEntrega'),
  dashboardImageSrc: servientregaDashboard
})
allParcels.set('deprisa', {
  imgSrc: deprisa,
  activated: true,
  image: (
    <img
      alt="deprisa"
      src={deprisa}
      style={{
        width: '60%',
        height: '60%',
        position: 'absolute'
      }}
    />
  ),
  comercialName: parcel_comercial_name('deprisa'),
  dashboardImageSrc: servientregaDashboard
})

allParcels.set('interrapidisimo', {
  imgSrc: interrapidisimo,
  activated: true,
  image: (
    <img
      alt="interrapidisimo"
      src={interrapidisimo2}
      style={{
        width: '80%',
        height: '70%',
        position: 'absolute'
      }}
    />
  ),
  comercialName: parcel_comercial_name('interrapidisimo'),
  dashboardImageSrc: interrapidisimoDashboard
})

allParcels.set('jtexpress', {
  imgSrc: jtexpress,
  activated: true,
  image: (
    <img
      alt="jtexpress"
      src={jtexpress}
      style={{
        width: 'auto',
        height: '75%',
        position: 'absolute'
      }}
    />
  ),
  comercialName: parcel_comercial_name('jtexpress'),
  dashboardImageSrc: jtexpressDashboard
})
allParcels.set('borzo', {
  imgSrc: borzo,
  activated: true,
  image: (
    <img
      alt="borzo"
      src={borzo}
      style={{
        width: 'auto',
        height: '75%',
        position: 'absolute'
      }}
    />
  ),
  comercialName: parcel_comercial_name('borzo'),
  dashboardImageSrc: borzoDashboard
})
allParcels.set('tresguerras', {
  imgSrc: treguerras,
  activated: true,
  image: (
    <img
      alt="tresguerras"
      src={treguerras}
      style={{
        width: '75%',
        height: 'auto',
        position: 'absolute'
      }}
    />
  ),
  comercialName: parcel_comercial_name('tresguerras'),
  dashboardImageSrc: treguerrasDashboard
})

allParcels.set('treggo', {
  imgSrc: treggo,
  activated: true,
  image: (
    <img
      alt="treggo"
      src={treggo}
      style={{
        width: '60%',
        height: '60%'
      }}
    />
  ),
  comercialName: parcel_comercial_name('treggo'),
  dashboardImageSrc: treggoDashboard
})
allParcels.set('pickit', {
  imgSrc: pickit,
  activated: true,
  image: (
    <img
      alt="pickit"
      src={pickit}
      style={{
        width: 'auto',
        height: '70%',
        position: 'absolute'
      }}
    />
  ),
  comercialName: parcel_comercial_name('pickit'),
  dashboardImageSrc: pickitDashboard
})

allParcels.set('moova', {
  imgSrc: moova,
  activated: true,
  image: (
    <img
      alt="moova"
      src={moova}
      style={{
        width: '80%',
        height: '60%'
      }}
    />
  ),
  comercialName: parcel_comercial_name('moova'),
  dashboardImageSrc: moovaDashboard
})

allParcels.set('olva', {
  imgSrc: olva,
  activated: true,
  image: (
    <img
      alt="olva"
      src={olva}
      style={{
        width: '80%',
        height: '60%'
      }}
    />
  ),
  comercialName: parcel_comercial_name('olva'),
  dashboardImageSrc: olvaDashboard
})

allParcels.set('nirex', {
  imgSrc: nirex,
  activated: true,
  image: (
    <img
      alt="nirex"
      src={nirex}
      style={{
        width: '80%',
        height: '60%'
      }}
    />
  ),
  comercialName: parcel_comercial_name('nirex'),
  dashboardImageSrc: nirexDashboard
})

allParcels.set('envia', {
  imgSrc: enviaco,
  activated: true,
  image: (
    <img
      alt="envia"
      src={enviaco}
      style={{
        width: '80%',
        height: '60%'
      }}
    />
  ),
  comercialName: parcel_comercial_name('envia'),
  dashboardImageSrc: enviacoDashboard
}) 
 
allParcels.set('picap', {
  imgSrc: picap,
  activated: true,
  image: (
    <img
      alt="picap"
      src={picap}
      style={{
        width: '90%',
        height: '70%'
      }}
    />
  ),
  comercialName: parcel_comercial_name('picap'),
  dashboardImageSrc: picapDashboard
})

export const allParcelsMapeable = []
allParcels.forEach((languageData, key) => allParcelsMapeable.push([key, languageData]))
